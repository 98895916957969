<template>
	<div class="content">
		<div style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
		  <!-- <img style="width: 30px;height: 30px;" src="../static/icon06.png"/> -->
		  <span style="color: #7C3BED;" @click="back()">返回</span>
		  <div v-if="userAddress" style="width: 200px;height: 40px;border-radius: 10px;text-align: center;line-height: 40px;background: #7C3BED33;color:white;">
		    <span>{{userAddress.substring(0,10)+'...'+userAddress.substring(36)}}</span>
		  </div>
		  <div v-if="!userAddress" style="width: 160px;height: 40px;border-radius: 10px;text-align: center;line-height: 40px;background: #7C3BED33;color: #7C3BED;"
		  			@click="connectBSC">
		    <span>链 接</span>
		  </div>
		</div>
		
		<div class="second" style="padding-top: 15px;">
			<textarea v-model="addresses" :rows="10" :cols="55" style="margin-top: 5px; margin-left: 5px; width: 100%;" placeholder="请输入多个要操作的地址,用英文逗号(',')隔开,每次最多支持100个地址"></textarea>
			<span style="color: yellow;">类型：</span><select v-model="addWhileType" placeholder="请选择白名单类型">
				<option :value="0">超级白名单</option>
				<option :value="1">群主白名单(与普通白名单不能共存)</option>
				<option :value="2">普通白名单(与群主白名单不能共存)</option>
			</select>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="addWhite(1)">
				<span>添加白名单</span>
			</div>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="addWhite(0)">
				<span>移除白名单</span>
			</div>
		</div>
		
		<div class="second" style="padding-top: 15px;">
			<textarea v-model="addresses2" :rows="10" :cols="55" style="margin-top: 5px; margin-left: 5px; width: 100%;" placeholder="请输入多个要操作的地址,用英文逗号(',')隔开,每次最多支持100个地址"></textarea>
			<span style="color: yellow;">类型：</span><select v-model="addMintType" placeholder="请选择白名单类型">
				<option :value="0">私募0.3</option>
				<option :value="2">私募0.2</option>
				<option :value="1">私募0.1</option>
			</select>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="addMintUser(1)">
				<span>添加私募用户</span>
			</div>
		</div>
		
		<div class="second">
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #7C3BED33;color: white;"
			 >
				<div v-if="mintEnable==0">当前阶段：未开启私募</div>
				<div v-if="mintEnable==1||mintEnable==2">当前阶段：已开启私募</div>
				<!-- <div v-if="mintEnable==2">当前阶段：普通白名单私募</div> -->
				<div v-if="mintEnable==3">当前阶段：私募已完成</div>
				<div v-if="mintEnable==4">当前阶段：白名单交易</div>
				<div v-if="mintEnable==5">当前阶段：正式交易</div>
				<div v-if="mintEnable==6">当前阶段：超级白名单交易</div>
			</div>
			<span style="color: yellow;">群主私募剩余进度：{{param[5]}}BNB</span><br>
			<span style="color: yellow;">普通私募剩余进度：{{param[6]}}BNB</span><br>
			<span style="color: red;">注意：私募完成后，开启白名单交易前需:1.用管理地址转给合约转账：{{dataStat[10]}}BNB <br>2.添加博饼bnb=PMM流动性并设置好交易对地址</span><br>
			<span style="color: red;">合约当前余额：{{dataStat2[0].toFixed(0)}}PMM,{{dataStat2[1].toFixed(4)}}BNB</span><br>
			<span style="color: yellow;">变更为：</span>
			<select v-model="updateParamValue" placeholder="请选择阶段">
				<option :value="0">1.未开启私募</option>
				<option :value="1">2.已开启私募</option>
				<!-- <option :value="2">3.普通白名单私募</option> -->
				<option :value="3">3.私募已完成</option>
				<option :value="4">4.白名单交易</option>
				<option :value="6">5.超级白名单交易</option>
				<option :value="5">6.正式交易</option>
			</select>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			 @click="startMint(true)">
				<span>点击变更</span>
			</div>
		</div>
		
		<div class="second">
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="clearDivieBnb">
				<span>清空BNB到创世地址(私募已完成时可操作)</span>
			</div>
		</div>
		
		<div class="second" style="padding-top: 15px;">
			<span style="color: red;">当前已燃烧次数：{{dataStat[0]}}</span>
			<span style="color: red;">最多燃烧次数：{{dataStat[3]}}</span><br>
			<span style="color: yellow;">值类型：</span>
			<select v-model="updateParamValue3" placeholder="请选择类型">
				<option :value="4">最多燃烧次数</option>
				<option :value="1">当前已燃烧次数</option>
				<option :value="5">币价涨幅太大关闭燃烧和领取奖励开关(1=启用,0=不启用)</option>
				<option :value="6">提前购买多少秒</option>
			</select>
			<textarea v-model="updateParamValue4" :rows="1" :cols="55" style="margin-top: 5px; margin-left: 5px;" placeholder="请输入值"></textarea>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="setParam(1)">
				<span>修改参数</span>
			</div>
		</div>
		
		<div class="second" style="padding-top: 15px;">
			<span style="color: yellow;">类型：</span>
			<select v-model="updateParamValue2" placeholder="请选择类型">
				<!-- <option :value="1">BNB/USDT兑换</option> -->
				<option :value="5">USDT/本币兑换</option>
				<option :value="2">BNB/本币兑换</option>
			</select>
			<textarea v-model="pankAddress" :rows="2" :cols="55" style="margin-top: 5px; margin-left: 5px;" placeholder="请输入博饼交易对地址"></textarea>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="setPankAddress(1)">
				<span>设置博饼交易对</span>
			</div>
		</div>
		
		<!-- <div class="second">
			<textarea v-model="_maxBurnCountDay" :rows="1" :cols="55" style="margin-top: 5px; margin-left: 5px;" placeholder="请输入下轮可燃烧数量"></textarea>
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="divid(1)">
				<span>分红(未自动分红成功就点一次)</span>
			</div>
		</div>
		
		<div class="second">
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="clearOutUser()">
				<span>清理出局用户(1-3天点一次)</span>
			</div>
		</div> -->

		
		<div class="second" style="padding-top: 0px;">
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #3138F0;color: white;"
			  @click="copyUrl()">
				<span>复制协议地址</span>
			</div>
		</div>
		<div class="second">
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #7C3BED33;color: red;"
			  @click="extendLiquidityLock()">
				<span>流动性锁200年</span>
			</div>
		</div>
		<div class="second">
			<div style="width: 90%;margin:10px auto;height: 48px;border-radius: 10px;text-align: center;line-height: 48px;margin-top: 20px;display: block;margin-left: 5%;font-size: 14px;background: #7C3BED33;color: red;"
			  @click="renounceOwnership()">
				<span>丢管理员权限</span>
			</div>
		</div>
		
	</div>
</template>

<!-- lang="ts" -->
<script>
	import Web3 from 'web3';
	import MyContractABI from "../abi/main.json"; // 导入合约 ABI
	import { message } from 'ant-design-vue'
	import Language from "../lan/lan.json"; //

	export default {
		// computed: {
		//   author () {
		//     return process.env.VUE_APP_AUTHOR;
		//   },
		//   apiBaseUrl () {
		//     return process.env.VUE_APP_API_BASE_URL;
		//   }
		// }
		data() {
			return {
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				
				//要修改的参数
				contractAddress:"0xc07cfe20bf28454c5704fa183f4337fdbf2e5d41",  //正式
				//contractAddress:"0xb953f3ef804af7dc94f2d6fc9ffe68a3a24a3620",  //测试迁移
				//networkId:97,
				networkId:56,
				//mintAmounts:[0.1,0.2,0.3], //正式环境 
				//end
				userAddress: "",
				dataStat2:[],
				dataStat:[],
				contract:{},
				_maxBurnCountDay:100,
				param:[],
				addWhileType:null,
				addMintType:null,
				tokenName:'PMM',
				web3: {},
				mintEnable:0,
				updateParamValue:null,
				updateParamValue2:null,
				addresses:'',
				addresses2:'',
				pankAddress:'',
				Language:Language,
				selectLan:'en',
			}
		},
		created() {
			this.connectBSC();
			let that = this;
			this.interval = setInterval(function(){
				that.loadData();
			},1000*15)
		},
		destroyed() {
			if(this.interval){
				clearInterval(this.interval);
			}
		},
		methods: {
			copyUrl(){
				var textarea = document.createElement('textarea');
				  textarea.style.position = 'fixed';
				  textarea.style.opacity = 0;
				  textarea.value = this.contractAddress;
				  document.body.appendChild(textarea);
				  textarea.select();
				  document.execCommand('copy');
				  document.body.removeChild(textarea);
				  message.success('success copy')
			},
			async renounceOwnership(){
				this.contract.methods.renounceOwnership().send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
					// 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				   console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
					// 在交易被打包后确认时触发的事件
					console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
					// 在交易失败时触发的事件
					//message.error(error);
					console.error('交易失败:', error);
				  });
			},
			async setPankAddress(){
				if(this.updateParamValue2==null){
					message.error("请选择交易对类型");
					return;
				}
				if(!this.pankAddress){
					message.error('请输入交易对地址');
				}
				this.updateParam(this.updateParamValue2,0,this.pankAddress);
			},
			async setParam(){
				if(this.updateParamValue3==null){
					message.error("请选择值类型");
					return;
				}
				if(!this.updateParamValue4){
					message.error('请输入值');
				}
				this.updateParam2(this.updateParamValue3,this.updateParamValue4,this.contractAddress);
				
			},
			async extendLiquidityLock(){
				this.web3.eth.getBlockNumber().then(blockNumber => {
					blockNumber = blockNumber + (60*60*24/3) * 365*200;
				    this.contract.methods.extendLiquidityLock(blockNumber).send({ from: this.userAddress })
				      .on('transactionHash', (hash) => {
				        // 在交易被发送到以太坊网络时触发的事件
				    	message.success("submit success");
				       console.log('交易哈希:', hash);
				      })
				      .on('receipt', (receipt) => {
				        // 在交易被打包后确认时触发的事件
				        console.log('交易收据:', receipt);
				    	//message.success(receipt.transactionHash);
						message.success("transaction success");
				    	this.loadData();
				      })
				      .on('error', (error) => {
				        // 在交易失败时触发的事件
				    	//message.error(error);
				        console.error('交易失败:', error);
				      });
				}).catch(error => {
				    console.error('错误: ' + error);
				});
				
			},
			async addWhite(result){
				this.addresses = this.addresses.replaceAll('，',',');
				this.addresses = this.addresses.replaceAll('\n','');
				if(this.addWhileType==null){
					message.error("请选择白名单类型");
					return;
				}
				let array = this.addresses.split(',');
				for(let i =0;i<array.length;i++){
					if(array[i].length !=42){
						message.error("地址错误:"+array[i]);
						return;
					}
				}
				
				this.contract.methods.addMintWhite(this.addWhileType,array,result).send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
			},
			async addMintUser(result){
				this.addresses2 = this.addresses2.replaceAll('，',',');
				this.addresses2 = this.addresses2.replaceAll('\n','');
				if(this.addMintType==null){
					message.error("请选择类型");
					return;
				}
				let array = this.addresses2.split(',');
				for(let i =0;i<array.length;i++){
					if(array[i].length !=42){
						message.error("地址错误:"+array[i]);
						return;
					}
				}
				
				this.contract.methods.addMintUser(this.addMintType,array).send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
			},
			async updateParam(value1,value2,value3){
				console.log('updateParam')
				this.contract.methods.updateParam(value1,value2,value3).send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
				
				//await this.contract.methods.band(this.bandAddress).call({});
				//message.success('success!');
			},
			async updateParam2(value1,value2,value3){
				console.log('updateParam')
				this.contract.methods.updateParam2(value1,value2,value3).send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
				
				//await this.contract.methods.band(this.bandAddress).call({});
				//message.success('success!');
			},
			async startMint(flag){
				if(this.updateParamValue==null){
					message.error("请选择变更阶段");
					return;
				}
				this.updateParam(3,parseInt(this.updateParamValue+''),this.contractAddress);
			},
			
			async divid(type){
				this.contract.methods.divid(this._maxBurnCountDay,type).send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
				
				//await this.contract.methods.band(this.bandAddress).call({});
				//message.success('success!');
			},
			
			async clearDivieBnb(){
				this.contract.methods.clearDivieBnb().send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
				
				//await this.contract.methods.band(this.bandAddress).call({});
				//message.success('success!');
			},
			
			async clearOutUser(type){
				this.contract.methods.clearOutUser().send({ from: this.userAddress })
				  .on('transactionHash', (hash) => {
				    // 在交易被发送到以太坊网络时触发的事件
					message.success("submit success");
				    console.log('交易哈希:', hash);
				  })
				  .on('receipt', (receipt) => {
				    // 在交易被打包后确认时触发的事件
				    console.log('交易收据:', receipt);
					//message.success(receipt.transactionHash);
					message.success("transaction success");
					this.loadData();
				  })
				  .on('error', (error) => {
				    // 在交易失败时触发的事件
					//message.error(error);
				    console.error('交易失败:', error);
				  });
				
				//await this.contract.methods.band(this.bandAddress).call({});
				//message.success('success!');
			},
			
			
			maxBurn(){
				this.burnAmount = this.tokenBalance;
			},
			back(){
				this.$router.replace('/?notTo=1');
			},
			async connectBSC() {
				// if (window.ethereum) {
				//     await window.ethereum.request({method: 'eth_requestAccounts'});
				//     window.web3 = new Web3(window.ethereum);
				//     return true;
				//   }
				let that = this;
				if (window.ethereum) {
					try {
						window.ethereum.on('accountsChanged', function (accounts) {
							// accounts 是一个数组，包含当前连接的所有账户
							console.log('change address:', accounts[0]);
							that.connectBSC();
							// 在这里处理账户变更后的逻辑
							// 例如更新界面上显示的账户信息等
						});
						console.log(window.ethereum, 'window.ethereum');
						// 请求用户授权连接到BSC
						const accounts = await window.ethereum.request({
							method: 'eth_requestAccounts'
						});

						console.log(accounts, 'accounts');

						// 创建web3实例
						this.web3 = new Web3(window.ethereum);

						// 获取用户地址
						this.userAddress = accounts[0].toLowerCase(); // 更新用户地址

						let bnbBalance = await this.web3.eth.getBalance(this.userAddress);
						this.bnbBalance = this.web3.utils.fromWei(bnbBalance, 'ether');


						// 加载合约
						console.log(MyContractABI,'MyContractABI');
						const networkId = await this.web3.eth.net.getId();
						//const deployedNetwork = MyContractABI.networks[networkId];
						if(networkId!=this.networkId){
							message.error('err network，please select BNB chain');
						}
						this.contract = new this.web3.eth.Contract(
							MyContractABI,
							// deployedNetwork && deployedNetwork.address
							this.contractAddress
						);
						

						this.connected = true;
						
						this.loadData();
					} catch (error) {
						console.error(error);
					}
				} else {
					alert("Please install MetaMask or a BSC-compatible wallet to use this DApp.");
				}
			},
			async loadData() {
			  if (this.contract) {
			    try {
			      // 调用合约方法
			      let dataStat = await this.contract.methods.getStatData().call({});
				  dataStat[0] = parseInt(dataStat[0]);
				  dataStat[3] = parseInt(dataStat[3]);
				  
				  let param = await this.contract.methods.getParam().call({});
				  let dataStat2 = await this.contract.methods.getStatData2().call({});
				  param[5] = this.web3.utils.fromWei(param[5], "ether");
				  param[6] = this.web3.utils.fromWei(param[6], "ether");
				  dataStat2[0] = parseFloat(this.web3.utils.fromWei(dataStat2[0], "ether"));
				  dataStat2[1] = parseFloat(this.web3.utils.fromWei(dataStat2[1], "ether"));
				  dataStat[10] = this.web3.utils.fromWei(dataStat[10], "ether");
			      this.mintEnable = parseInt(dataStat[8]);
				  this.param = param;
				  this.dataStat2 = dataStat2;
				  this.dataStat = dataStat;
			      console.log(dataStat, "dataStat:");
				  
				 //  for (let i = 42073000; i >=42070000; i--) {
				    
				 //    let blockPrice = await this.contract.methods.blockPrice( i).call({});
					// if(parseInt(blockPrice)>0){
					// 						console.log(i+'='+blockPrice);
					// }
				  		
				 //  }
			    } catch (error) {
			      console.error(error);
			    }
			  } else {
			    console.error("Contract not initialized.");
			  }
			}
		}
	}
</script>


<style>
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.second {
		width: 90%;
		margin: 10px auto;
		border-radius: 15px;
		box-shadow: 1px 4px 4px 4px #d8d8d8;
		display: block;
	}

	.second01 {
		width: 90%;
		margin: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
	}

	.second02 {
		font-size: 32px;
	}

	.second03 {
		width: 40px;
		height: 40px;
	}

	.second04 {
		width: 90%;
		margin: 18px auto;
		border: 1px solid #d9d9d9;
		height: 180px;
		border-radius: 20px;
		display: block;
	}

	.second05 {
		width: 90%;
		margin: 25px auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.second09 {
		display: inline-block;
		width: 62px;
		height: 40px;
		border-radius: 12px;
		text-align: center;
		line-height: 40px;
		background-color: #7C3BED33;
		color: #7C3BED;
		font-size: 24px;
		margin-left: 20px;
	}
</style>